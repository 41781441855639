@media(max-width: 375px) {
 
    
    h2 {
        font-size: 1.2rem;
    }

    .image-item {
        height: 340px;
    }

    .logotype {
        height: 100px
    }
 
}

@media(max-width: 360px) {
    .image-item {
        height: 270px
    }

   
}



@media(max-width: 320px) {
    h1 {
        font-size: 1.1rem;
    }

    h2 {
        font-size: 1rem;
    }

    .image-item {

        height: 220px
    }
}

@media(max-width: 1024px) {

    body {
       
        background: url(/mobile.jpg) 50% 50% / cover no-repeat !important;
        background-attachment: fixed !important;
        background-position: center center !important;

    }

    .about-body{
       
        background: url(/mobile.jpg) 50% 50% / cover no-repeat !important;
        background-attachment: fixed !important;
        background-position: center center !important;

    }

    .blackhole-body{
       
        background: url(/mobile.jpg) 50% 50% / cover no-repeat !important;
        background-attachment: fixed !important;
        background-position: center center !important;
    }

    .galaxy-body{
       
        background: url(/mobile.jpg) 50% 50% / cover no-repeat !important;
        background-attachment: fixed !important;
        background-position: center center !important;
    }

    .nebula-body{
       
        background: url(/mobile.jpg) 50% 50% / cover no-repeat !important;
        background-attachment: fixed !important;
        background-position: center center !important;
    }
    .planet-body{
       
        background: url(/mobile.jpg) 50% 50% / cover no-repeat !important;
        background-attachment: fixed !important;
        background-position: center center !important;
    }

    .project-body{
       
        background: url(/mobile.jpg) 50% 50% / cover no-repeat !important;
        background-attachment: fixed !important;
        background-position: center center !important;
    }
    .space-body{
       
        background: url(/mobile.jpg) 50% 50% / cover no-repeat !important;
        background-attachment: fixed !important;
        background-position: center center !important;
    }
    .star-body{
       
        background: url(/mobile.jpg) 50% 50% / cover no-repeat !important;
        background-attachment: fixed !important;
        background-position: center center !important;
    }
}


@media(max-width: 414px) {
    h1 {
        font-size: 1.4rem;
    }

    h2 {
        font-size: 1.3rem;
    }

    .image-item {
        height: 300px
    }
}