
.galaxy-body{
    background: url(/galaxy.jpg) 50% 50% / cover no-repeat;
    position: relative;
    overflow-x: hidden;
    height: 100%;
    width: 100%;
    z-index: -99999;
    background-attachment: fixed;
}

section{
    text-align: center;
}


.imageGalaxy{
    font-family: 'Boogaloo', cursive;
    color: white;
    font-size: 25px;
    display: inline-block;
}

.galaxyInfo{
    color: white;
    font-size: 23px;
    display: inline-block;
    font-family: 'Boogaloo', cursive;
    @media(max-width: 375px) {
            font-size: 17px;
        }
}

.galaxyImage1{
    height: 500px;
    @media(max-width: 768px){
        height: 389px;
    }
    @media(max-width: 375px){
        height: 200px;
    }

    @media(max-width: 414px){
        height: 200px;
    }

    @media(max-width: 320px){
        height: 170px;
    }
}

.galaxyImage{
    height: 555px;
    @media(max-width: 768px ){
        height: 430px ;
    }
    @media(max-width: 375px){
        height: 222px;
    }

    @media(max-width: 414px){
        height: 222px;

    }
    @media(max-width: 320px){
        height: 189px;
    }


}

.image-item{
    @media(max-width: 768px){
        height: 320px;
    }
    @media(max-width: 375px){
        height: 280px;
    }
    @media(max-width: 320px){
        height: 230px;
    }


}
