.project-body{
    background: url(/project.jpg) 50% 50% / cover no-repeat;
    position: relative;
    overflow-x: hidden;
    height: 100%;
    width: 100%;
    z-index: -99999;
    background-attachment: fixed;
    
}

.info{
    text-align: center;
}

.thanks{
    text-align: center;
    color: white;
    display: inline-block;
    width: 50%; 
  
}

.hand{
    animation-name: flipInY;
    backface-visibility: visible !important;
    animation-duration: 1.3s; 
    @media(max-width: 768px){
        height: 200px ;
    }
    @media(max-width: 411px){
        height: 140px;
    }
}