.star-body{
    background: url(/star.jpg) 50% 50% / cover no-repeat;
    position: relative;
    overflow-x: hidden;
    height: 100%;
    width: 100%;
    z-index: -99999;
    background-attachment: fixed;
}

.starInfo{
    color: white;
    font-size: 23px;
    display: inline-block;
    font-family: 'Boogaloo', cursive;
    @media(max-width: 375px) {
            font-size: 15px;
        }
}

.starImage{
    height: 555px;
    @media(max-width: 768px ){
        height: 410px ;
    }
    @media(max-width: 375px){
        height: 210px;
    }

    @media(max-width: 414px){
        height: 210px;

    }
    @media(max-width: 320px){
        height: 180px;
    }


}

.image-item{
    @media(max-width: 768px){
        height: 300px;
    }
    @media(max-width: 375px){
        height: 260px;
    }
    @media(max-width: 320px){
        height: 210px;
    }


}
