@import url(https://fonts.googleapis.com/css?family=Poiret+One&display=swap);
@import url(https://fonts.googleapis.com/css?family=Boogaloo&display=swap);
@import url(https://fonts.googleapis.com/css?family=Jura&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media (max-width: 375px) {
  h2 {
    font-size: 1.2rem; }
  .image-item {
    height: 340px; }
  .logotype {
    height: 100px; } }

@media (max-width: 360px) {
  .image-item {
    height: 270px; } }

@media (max-width: 320px) {
  h1 {
    font-size: 1.1rem; }
  h2 {
    font-size: 1rem; }
  .image-item {
    height: 220px; } }

@media (max-width: 1024px) {
  body {
    background: url(/mobile.jpg) 50% 50%/cover no-repeat !important;
    background-attachment: fixed !important;
    background-position: center center !important; }
  .about-body {
    background: url(/mobile.jpg) 50% 50%/cover no-repeat !important;
    background-attachment: fixed !important;
    background-position: center center !important; }
  .blackhole-body {
    background: url(/mobile.jpg) 50% 50%/cover no-repeat !important;
    background-attachment: fixed !important;
    background-position: center center !important; }
  .galaxy-body {
    background: url(/mobile.jpg) 50% 50%/cover no-repeat !important;
    background-attachment: fixed !important;
    background-position: center center !important; }
  .nebula-body {
    background: url(/mobile.jpg) 50% 50%/cover no-repeat !important;
    background-attachment: fixed !important;
    background-position: center center !important; }
  .planet-body {
    background: url(/mobile.jpg) 50% 50%/cover no-repeat !important;
    background-attachment: fixed !important;
    background-position: center center !important; }
  .project-body {
    background: url(/mobile.jpg) 50% 50%/cover no-repeat !important;
    background-attachment: fixed !important;
    background-position: center center !important; }
  .space-body {
    background: url(/mobile.jpg) 50% 50%/cover no-repeat !important;
    background-attachment: fixed !important;
    background-position: center center !important; }
  .star-body {
    background: url(/mobile.jpg) 50% 50%/cover no-repeat !important;
    background-attachment: fixed !important;
    background-position: center center !important; } }

@media (max-width: 414px) {
  h1 {
    font-size: 1.4rem; }
  h2 {
    font-size: 1.3rem; }
  .image-item {
    height: 300px; } }

menu-trigger span:nth-of-type(1) {
  top: 0; }

.menu-trigger span:nth-of-type(2) {
  top: 20px; }

.menu-trigger span:nth-of-type(3) {
  bottom: 0; }

.menu-trigger.type7 span:nth-of-type(1) {
  -webkit-animation: menu-bar07-01 .75s forwards;
  animation: menu-bar07-01 .75s forwards; }

@-webkit-keyframes menu-bar07-01 {
  0% {
    -webkit-transform: translateY(20px) rotate(45deg); }
  50% {
    -webkit-transform: translateY(20px) rotate(0); }
  100% {
    -webkit-transform: translateY(0) rotate(0); } }

@keyframes menu-bar07-01 {
  0% {
    -webkit-transform: translateY(20px) rotate(45deg);
            transform: translateY(20px) rotate(45deg); }
  50% {
    -webkit-transform: translateY(20px) rotate(0);
            transform: translateY(20px) rotate(0); }
  100% {
    -webkit-transform: translateY(0) rotate(0);
            transform: translateY(0) rotate(0); } }

.menu-trigger.type7 span:nth-of-type(2) {
  -webkit-transition: all .25s .25s;
  transition: all .25s .25s;
  opacity: 1; }

.menu-trigger.type7 span:nth-of-type(3) {
  -webkit-animation: menu-bar07-02 .75s forwards;
  animation: menu-bar07-02 .75s forwards; }

@-webkit-keyframes menu-bar07-02 {
  0% {
    -webkit-transform: translateY(-20px) rotate(-45deg); }
  50% {
    -webkit-transform: translateY(-20px) rotate(0); }
  100% {
    -webkit-transform: translateY(0) rotate(0); } }

@keyframes menu-bar07-02 {
  0% {
    -webkit-transform: translateY(-20px) rotate(-45deg);
            transform: translateY(-20px) rotate(-45deg); }
  50% {
    -webkit-transform: translateY(-20px) rotate(0);
            transform: translateY(-20px) rotate(0); }
  100% {
    -webkit-transform: translateY(0) rotate(0);
            transform: translateY(0) rotate(0); } }

.menu-trigger.active span:nth-of-type(1) {
  -webkit-animation: active-menu-bar07-01 .75s forwards;
  animation: active-menu-bar07-01 .75s forwards; }

@-webkit-keyframes active-menu-bar07-01 {
  0% {
    -webkit-transform: translateY(0) rotate(0); }
  50% {
    -webkit-transform: translateY(20px) rotate(0); }
  100% {
    -webkit-transform: translateY(20px) rotate(45deg); } }

@keyframes active-menu-bar07-01 {
  0% {
    -webkit-transform: translateY(0) rotate(0);
            transform: translateY(0) rotate(0); }
  50% {
    -webkit-transform: translateY(20px) rotate(0);
            transform: translateY(20px) rotate(0); }
  100% {
    -webkit-transform: translateY(20px) rotate(45deg);
            transform: translateY(20px) rotate(45deg); } }

.menu-trigger.active span:nth-of-type(2) {
  opacity: 0; }

.menu-trigger.active span:nth-of-type(3) {
  -webkit-animation: active-menu-bar07-02 .75s forwards;
  animation: active-menu-bar07-02 .75s forwards; }

@-webkit-keyframes active-menu-bar07-02 {
  0% {
    -webkit-transform: translateY(0) rotate(0); }
  50% {
    -webkit-transform: translateY(-20px) rotate(0); }
  100% {
    -webkit-transform: translateY(-20px) rotate(-45deg); } }

@keyframes active-menu-bar07-02 {
  0% {
    -webkit-transform: translateY(0) rotate(0);
            transform: translateY(0) rotate(0); }
  50% {
    -webkit-transform: translateY(-20px) rotate(0);
            transform: translateY(-20px) rotate(0); }
  100% {
    -webkit-transform: translateY(-20px) rotate(-45deg);
            transform: translateY(-20px) rotate(-45deg); } }

@-webkit-keyframes burger-animation {
  from {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%); }
  to {
    -webkit-transform: translateY(0%);
            transform: translateY(0%); } }

@keyframes burger-animation {
  from {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%); }
  to {
    -webkit-transform: translateY(0%);
            transform: translateY(0%); } }

.slideDown {
  animation-name: slideDown;
  -webkit-animation-name: slideDown;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important; }

@keyframes slideDown {
  0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%); }
  50% {
    -webkit-transform: translateY(8%);
            transform: translateY(8%); }
  65% {
    -webkit-transform: translateY(-4%);
            transform: translateY(-4%); }
  80% {
    -webkit-transform: translateY(4%);
            transform: translateY(4%); }
  95% {
    -webkit-transform: translateY(-2%);
            transform: translateY(-2%); }
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%); } }

@-webkit-keyframes slideDown {
  0% {
    -webkit-transform: translateY(-100%); }
  50% {
    -webkit-transform: translateY(8%); }
  65% {
    -webkit-transform: translateY(-4%); }
  80% {
    -webkit-transform: translateY(4%); }
  95% {
    -webkit-transform: translateY(-2%); }
  100% {
    -webkit-transform: translateY(0%); } }

.image-item {
  -webkit-animation: 42.5s linear 0s normal none infinite running logotype;
          animation: 42.5s linear 0s normal none infinite running logotype; }

@keyframes logotype {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@-webkit-keyframes logotype {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.pulse {
  animation-name: pulse;
  -webkit-animation-name: pulse;
  animation-duration: 1.5s;
  -webkit-animation-duration: 1.5s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite; }

@keyframes pulse {
  0% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
    opacity: 0.7; }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; }
  100% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
    opacity: 0.7; } }

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(0.95);
    opacity: 0.7; }
  50% {
    -webkit-transform: scale(1);
    opacity: 1; }
  100% {
    -webkit-transform: scale(0.95);
    opacity: 0.7; } }

@-webkit-keyframes toLeft {
  from {
    -webkit-transform-origin: left bottom;
            transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
            transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; }
  to {
    -webkit-transform-origin: left bottom;
            transform-origin: left bottom;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    opacity: 1; } }

@keyframes toLeft {
  from {
    -webkit-transform-origin: left bottom;
            transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
            transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; }
  to {
    -webkit-transform-origin: left bottom;
            transform-origin: left bottom;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
    opacity: 1; } }

@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@-webkit-keyframes fadeInRightBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes fadeInRightBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@-webkit-keyframes fadeOutLeftBig {
  from {
    -webkit-transform: translate3d(-2000px, 0, 0);
            transform: translate3d(-2000px, 0, 0);
    opacity: 0; }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0px, 0, 0);
            transform: translate3d(0px, 0, 0); } }

@keyframes fadeOutLeftBig {
  from {
    -webkit-transform: translate3d(-2000px, 0, 0);
            transform: translate3d(-2000px, 0, 0);
    opacity: 0; }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0px, 0, 0);
            transform: translate3d(0px, 0, 0); } }

@-webkit-keyframes hue {
  from {
    -webkit-filter: hue-rotate(0deg);
            filter: hue-rotate(0deg); }
  to {
    -webkit-filter: hue-rotate(-360deg);
            filter: hue-rotate(-360deg); } }

@keyframes hue {
  from {
    -webkit-filter: hue-rotate(0deg);
            filter: hue-rotate(0deg); }
  to {
    -webkit-filter: hue-rotate(-360deg);
            filter: hue-rotate(-360deg); } }

@-webkit-keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3); }
  50% {
    opacity: 1; } }

@keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3); }
  50% {
    opacity: 1; } }

@-webkit-keyframes flipInY {
  from {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
            transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
            transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in; }
  60% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
            transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1; }
  80% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
            transform: perspective(400px) rotate3d(0, 1, 0, -5deg); }
  to {
    -webkit-transform: perspective(400px);
            transform: perspective(400px); } }

@keyframes flipInY {
  from {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
            transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
            transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in; }
  60% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
            transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1; }
  80% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
            transform: perspective(400px) rotate3d(0, 1, 0, -5deg); }
  to {
    -webkit-transform: perspective(400px);
            transform: perspective(400px); } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url(https://i.pinimg.com/originals/85/f1/a8/85f1a8c45ef808dd5f635d8ed5404818.jpg) 50% 50%/cover no-repeat;
  background-attachment: fixed;
  position: relative;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  z-index: -99999; }

#menu {
  padding: 0; }

.head {
  display: grid;
  align-items: center;
  width: 100%;
  height: auto;
  justify-content: center;
  z-index: 10000; }
  @media (max-width: 991px) {
    .head {
      display: flex;
      justify-content: space-around; } }

footer {
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  padding-top: 40px;
  text-align: center;
  padding-top: 150px;
  margin-top: 90px; }
  footer p {
    color: white;
    font-size: 20px; }

ul {
  font-family: 'Poiret One', cursive;
  display: flex;
  justify-content: space-around;
  font-size: x-large;
  font-weight: bold; }

h1, h2 {
  font-family: 'Poiret One', cursive; }

a {
  text-decoration: none;
  color: white; }
  a:hover {
    text-decoration: none; }

li {
  padding: 0 30px;
  list-style: none;
  color: white;
  position: relative;
  -webkit-animation-name: fadeInRight;
          animation-name: fadeInRight;
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s; }
  li:after {
    content: '';
    display: block;
    margin: auto;
    height: 2px;
    width: 0px;
    -webkit-transition: width .5s ease, background-color .5s ease;
    transition: width .5s ease, background-color .5s ease; }
  li:hover:after {
    width: 100%;
    background: white; }
  @media (max-width: 991px) {
    li:hover:after {
      width: 10%;
      background: white; } }

.logo {
  text-align: center; }

.logotype {
  height: 110px;
  -webkit-animation-name: toLeft;
          animation-name: toLeft;
  -webkit-animation-duration: 1s;
          animation-duration: 1s; }

.navig {
  display: none;
  position: relative;
  left: 0;
  right: 0;
  top: 0; }
  @media (min-width: 991px) {
    .navig {
      display: flex; } }

.pre-info {
  letter-spacing: 5px;
  margin: 0;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: white;
  padding-top: 100px;
  -webkit-animation-name: fadeInRightBig;
          animation-name: fadeInRightBig;
  -webkit-animation-duration: 1s;
          animation-duration: 1s; }

.space {
  padding: 1em 3em;
  border: 3px solid white;
  position: relative;
  top: 120px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.2); }
  .space:before {
    position: absolute;
    content: '';
    width: 0%;
    height: 0%;
    background: white;
    top: 50%;
    left: 50%;
    z-index: -1;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease; }
  .space:hover {
    color: black;
    box-shadow: none; }
    .space:hover:before {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      background: white;
      top: 0;
      left: 0%;
      z-index: -1;
      -webkit-transition: all 300ms ease;
      transition: all 300ms ease; }

.burger {
  display: block; }
  @media (min-width: 991px) {
    .burger {
      display: none; } }

.trigger {
  position: relative;
  display: block;
  margin: 3px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  z-index: 40; }

.trigger i {
  display: block;
  width: 30px;
  width: 30px;
  height: 4px;
  margin: 4px auto;
  background-color: black; }

.menu-trigger {
  margin-right: 70px;
  margin-bottom: 50px;
  margin: 0;
  padding: 0;
  position: relative;
  width: 50px;
  height: 44px;
  z-index: 50; }
  .menu-trigger span {
    background-color: white; }

.menu-trigger,
.menu-trigger span {
  display: inline-block;
  -webkit-transition: all .4s;
  transition: all .4s;
  box-sizing: border-box; }

.menu-trigger span {
  position: absolute;
  left: 0;
  width: 100%;
  height: 4px;
  border-radius: 4px; }

#content {
  position: fixed;
  flex-direction: column;
  top: 0;
  right: 0;
  width: 100%;
  height: 256px;
  z-index: 30;
  text-align: center; }
  #content.show {
    margin: 0;
    -webkit-animation: burger-animation 0.5s linear;
            animation: burger-animation 0.5s linear;
    display: flex;
    background-color: rgba(0, 0, 0, 0.911); }

.space-body {
  background: url(/space.jpg) 50% 50%/cover no-repeat;
  position: relative;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  z-index: -99999;
  background-attachment: fixed; }

.contain {
  margin-top: 100px;
  padding-bottom: 45px;
  text-align: center;
  font-size: x-large;
  color: white;
  font-family: 'Boogaloo', cursive; }

.item {
  -webkit-transition: 1s;
  transition: 1s;
  -webkit-animation-name: fadeOutLeftBig;
          animation-name: fadeOutLeftBig;
  -webkit-animation-duration: 2s;
          animation-duration: 2s; }
  .item:hover {
    -webkit-transform: scale(1.15);
    transform: scale(1.15); }

h1 {
  color: white; }

.item {
  display: inline-block; }

.galaxy-body {
  background: url(/galaxy.jpg) 50% 50%/cover no-repeat;
  position: relative;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  z-index: -99999;
  background-attachment: fixed; }

section {
  text-align: center; }

.imageGalaxy {
  font-family: 'Boogaloo', cursive;
  color: white;
  font-size: 25px;
  display: inline-block; }

.galaxyInfo {
  color: white;
  font-size: 23px;
  display: inline-block;
  font-family: 'Boogaloo', cursive; }
  @media (max-width: 375px) {
    .galaxyInfo {
      font-size: 17px; } }

.galaxyImage1 {
  height: 500px; }
  @media (max-width: 768px) {
    .galaxyImage1 {
      height: 389px; } }
  @media (max-width: 375px) {
    .galaxyImage1 {
      height: 200px; } }
  @media (max-width: 414px) {
    .galaxyImage1 {
      height: 200px; } }
  @media (max-width: 320px) {
    .galaxyImage1 {
      height: 170px; } }

.galaxyImage {
  height: 555px; }
  @media (max-width: 768px) {
    .galaxyImage {
      height: 430px; } }
  @media (max-width: 375px) {
    .galaxyImage {
      height: 222px; } }
  @media (max-width: 414px) {
    .galaxyImage {
      height: 222px; } }
  @media (max-width: 320px) {
    .galaxyImage {
      height: 189px; } }

@media (max-width: 768px) {
  .image-item {
    height: 320px; } }

@media (max-width: 375px) {
  .image-item {
    height: 280px; } }

@media (max-width: 320px) {
  .image-item {
    height: 230px; } }

.star-body {
  background: url(/star.jpg) 50% 50%/cover no-repeat;
  position: relative;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  z-index: -99999;
  background-attachment: fixed; }

.starInfo {
  color: white;
  font-size: 23px;
  display: inline-block;
  font-family: 'Boogaloo', cursive; }
  @media (max-width: 375px) {
    .starInfo {
      font-size: 15px; } }

.starImage {
  height: 555px; }
  @media (max-width: 768px) {
    .starImage {
      height: 410px; } }
  @media (max-width: 375px) {
    .starImage {
      height: 210px; } }
  @media (max-width: 414px) {
    .starImage {
      height: 210px; } }
  @media (max-width: 320px) {
    .starImage {
      height: 180px; } }

@media (max-width: 768px) {
  .image-item {
    height: 300px; } }

@media (max-width: 375px) {
  .image-item {
    height: 260px; } }

@media (max-width: 320px) {
  .image-item {
    height: 210px; } }

.planet-body {
  background: url(/planet.jpg) 50% 50%/cover no-repeat;
  position: relative;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  z-index: -99999;
  background-attachment: fixed; }

.planetInfo {
  color: white;
  font-size: 23px;
  display: inline-block;
  font-family: 'Boogaloo', cursive; }
  @media (max-width: 375px) {
    .planetInfo {
      font-size: 15px; } }

.planetImage {
  height: 555px; }
  @media (max-width: 768px) {
    .planetImage {
      height: 410px; } }
  @media (max-width: 375px) {
    .planetImage {
      height: 210px; } }
  @media (max-width: 414px) {
    .planetImage {
      height: 210px; } }
  @media (max-width: 320px) {
    .planetImage {
      height: 180px; } }

@media (max-width: 768px) {
  .image-item {
    height: 300px; } }

@media (max-width: 375px) {
  .image-item {
    height: 260px; } }

@media (max-width: 320px) {
  .image-item {
    height: 210px; } }

.nebula-body {
  background: url(/nebula.jpg) 50% 50%/cover no-repeat;
  position: relative;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  z-index: -99999;
  background-attachment: fixed; }

.nebulaInfo {
  color: white;
  font-size: 23px;
  display: inline-block;
  font-family: 'Boogaloo', cursive; }
  @media (max-width: 375px) {
    .nebulaInfo {
      font-size: 15px; } }

.blackhole-body {
  background: url(/blackhole.jpg) 50% 50%/cover no-repeat;
  position: relative;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  z-index: -99999;
  background-attachment: fixed; }

.blackholeInfo {
  color: white;
  font-size: 23px;
  display: inline-block;
  font-family: 'Boogaloo', cursive; }
  @media (max-width: 375px) {
    .blackholeInfo {
      font-size: 15px; } }

.about-body {
  background-image: url(/about.jpg);
  position: relative;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  z-index: -99999;
  background-attachment: fixed; }

.wrapper {
  color: white;
  font-size: 30px;
  text-align: center;
  display: inline-block;
  align-items: center;
  padding: 30px; }
  @media (max-width: 768px) {
    .wrapper {
      font-size: 15px; } }

.content {
  text-align: center;
  -webkit-animation-name: fadeInRightBig;
          animation-name: fadeInRightBig;
  -webkit-animation-duration: 1s;
          animation-duration: 1s; }

.container {
  background-color: rgba(255, 255, 255, 0.74);
  color: black;
  font-size: x-large;
  text-align: center; }
  .container h1 {
    color: black; }
  .container p {
    margin-left: 100px;
    padding-top: 100px;
    font-family: 'Jura', sans-serif;
    border-bottom: 3px solid black;
    -webkit-animation-name: zoomIn;
            animation-name: zoomIn;
    -webkit-animation-duration: 2s;
            animation-duration: 2s; }
    @media (max-width: 768px) {
      .container p {
        font-size: 16px;
        margin-left: 0;
        border: none; } }

@media (max-width: 768px) {
  .sponsors {
    height: 100px; } }

@media (max-width: 600px) {
  .sponsors {
    display: none; } }

@media (max-width: 600px) {
  .spaceX {
    display: none; } }

.col-md-6 {
  width: 45%; }
  @media (max-width: 768px) {
    .col-md-6 {
      width: 100%; } }

.row {
  display: flex; }
  @media (max-width: 768px) {
    .row {
      display: block; } }

.helper {
  display: inline-flex;
  text-align: center;
  align-items: center; }

.project-body {
  background: url(/project.jpg) 50% 50%/cover no-repeat;
  position: relative;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  z-index: -99999;
  background-attachment: fixed; }

.info {
  text-align: center; }

.thanks {
  text-align: center;
  color: white;
  display: inline-block;
  width: 50%; }

.hand {
  -webkit-animation-name: flipInY;
          animation-name: flipInY;
  -webkit-backface-visibility: visible !important;
          backface-visibility: visible !important;
  -webkit-animation-duration: 1.3s;
          animation-duration: 1.3s; }
  @media (max-width: 768px) {
    .hand {
      height: 200px; } }
  @media (max-width: 411px) {
    .hand {
      height: 140px; } }

