menu-trigger span:nth-of-type(1) {
    top: 0;
}

.menu-trigger span:nth-of-type(2) {
    top: 20px;
}

.menu-trigger span:nth-of-type(3) {
    bottom: 0;
}


.menu-trigger.type7 span:nth-of-type(1) {
    -webkit-animation: menu-bar07-01 .75s forwards;
    animation: menu-bar07-01 .75s forwards;
}

@-webkit-keyframes menu-bar07-01 {
    0% {
        -webkit-transform: translateY(20px) rotate(45deg);
    }

    50% {
        -webkit-transform: translateY(20px) rotate(0);
    }

    100% {
        -webkit-transform: translateY(0) rotate(0);
    }
}

@keyframes menu-bar07-01 {
    0% {
        transform: translateY(20px) rotate(45deg);
    }

    50% {
        transform: translateY(20px) rotate(0);
    }

    100% {
        transform: translateY(0) rotate(0);
    }
}

.menu-trigger.type7 span:nth-of-type(2) {
    transition: all .25s .25s;
    opacity: 1;
}

.menu-trigger.type7 span:nth-of-type(3) {
    -webkit-animation: menu-bar07-02 .75s forwards;
    animation: menu-bar07-02 .75s forwards;
}

@-webkit-keyframes menu-bar07-02 {
    0% {
        -webkit-transform: translateY(-20px) rotate(-45deg);
    }

    50% {
        -webkit-transform: translateY(-20px) rotate(0);
    }

    100% {
        -webkit-transform: translateY(0) rotate(0);
    }
}

@keyframes menu-bar07-02 {
    0% {
        transform: translateY(-20px) rotate(-45deg);
    }

    50% {
        transform: translateY(-20px) rotate(0);
    }

    100% {
        transform: translateY(0) rotate(0);
    }
}

.menu-trigger.active span:nth-of-type(1) {
    -webkit-animation: active-menu-bar07-01 .75s forwards;
    animation: active-menu-bar07-01 .75s forwards;
}

@-webkit-keyframes active-menu-bar07-01 {
    0% {
        -webkit-transform: translateY(0) rotate(0);
    }

    50% {
        -webkit-transform: translateY(20px) rotate(0);
    }

    100% {
        -webkit-transform: translateY(20px) rotate(45deg);
    }
}

@keyframes active-menu-bar07-01 {
    0% {
        transform: translateY(0) rotate(0);
    }

    50% {
        transform: translateY(20px) rotate(0);
    }

    100% {
        transform: translateY(20px) rotate(45deg);
    }
}

.menu-trigger.active span:nth-of-type(2) {
    opacity: 0;
}

.menu-trigger.active span:nth-of-type(3) {
    -webkit-animation: active-menu-bar07-02 .75s forwards;
    animation: active-menu-bar07-02 .75s forwards;
}

@-webkit-keyframes active-menu-bar07-02 {
    0% {
        -webkit-transform: translateY(0) rotate(0);
    }

    50% {
        -webkit-transform: translateY(-20px) rotate(0);
    }

    100% {
        -webkit-transform: translateY(-20px) rotate(-45deg);
    }
}

@keyframes active-menu-bar07-02 {
    0% {
        transform: translateY(0) rotate(0);
    }

    50% {
        transform: translateY(-20px) rotate(0);
    }

    100% {
        transform: translateY(-20px) rotate(-45deg);
    }
}

@keyframes burger-animation { 
    from{
        transform: translateY(-100%)
    } to{
        transform: translateY(0%)
    }
}



.slideDown{
    animation-name: slideDown;
    -webkit-animation-name: slideDown;  
 
    animation-duration: 1s; 
    -webkit-animation-duration: 1s;
 
    animation-timing-function: ease;    
    -webkit-animation-timing-function: ease;    
 
    visibility: visible !important;                     
}
 
@keyframes slideDown {
    0% {
        transform: translateY(-100%);
    }
    50%{
        transform: translateY(8%);
    }
    65%{
        transform: translateY(-4%);
    }
    80%{
        transform: translateY(4%);
    }
    95%{
        transform: translateY(-2%);
    }           
    100% {
        transform: translateY(0%);
    }       
}
 
@-webkit-keyframes slideDown {
    0% {
        -webkit-transform: translateY(-100%);
    }
    50%{
        -webkit-transform: translateY(8%);
    }
    65%{
        -webkit-transform: translateY(-4%);
    }
    80%{
        -webkit-transform: translateY(4%);
    }
    95%{
        -webkit-transform: translateY(-2%);
    }           
    100% {
        -webkit-transform: translateY(0%);
    }   
}



.image-item {
    animation: 42.5s linear 0s normal none infinite running logotype;

    @keyframes logotype {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    @-webkit-keyframes logotype {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

}

.pulse
{
    animation-name: pulse;
    -webkit-animation-name: pulse;  
 
    animation-duration: 1.5s;   
    -webkit-animation-duration: 1.5s;
 
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
}
 
@keyframes pulse {
    0% {
        transform: scale(0.9);
        opacity: 0.7;       
    }
    50% {
        transform: scale(1);
        opacity: 1; 
    }   
    100% {
        transform: scale(0.9);
        opacity: 0.7;   
    }           
}
 
@-webkit-keyframes pulse {
    0% {
        -webkit-transform: scale(0.95);
        opacity: 0.7;       
    }
    50% {
        -webkit-transform: scale(1);
        opacity: 1; 
    }   
    100% {
        -webkit-transform: scale(0.95);
        opacity: 0.7;   
    }           
}

@keyframes toLeft {
    from {
      transform-origin: left bottom;
      transform: rotate3d(0, 0, 1, -45deg);
      opacity: 0;
    }
  
    to {
      transform-origin: left bottom;
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }
  
  @keyframes fadeInRight {
    from {
      opacity: 0;
      transform: translate3d(100%, 0, 0);
    }
  
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
}

@keyframes fadeInRightBig {
    from {
      opacity: 0;
      transform: translate3d(2000px, 0, 0);
    }
  
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  @keyframes fadeOutLeftBig {
    from {
        transform: translate3d(-2000px,0,0);
      opacity: 0;
    }
  
    to {
      opacity: 1;
      transform: translate3d(0px, 0, 0);
    }
  }

  @keyframes hue {
    from {
      filter: hue-rotate(0deg);
    }
    
    to {
      filter: hue-rotate(-360deg);
    }
  }

  @keyframes zoomIn {
    from {
      opacity: 0;
      transform: scale3d(0.3, 0.3, 0.3);
    }
  
    50% {
      opacity: 1;
    }
  }

  @keyframes flipInY {
    from {
      transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
      animation-timing-function: ease-in;
      opacity: 0;
    }
  
    40% {
      transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
      animation-timing-function: ease-in;
    }
  
    60% {
      transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
      opacity: 1;
    }
  
    80% {
      transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    }
  
    to {
      transform: perspective(400px);
    }
  }
