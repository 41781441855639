.space-body{
    background: url(/space.jpg) 50% 50% / cover no-repeat;
    position: relative;
    overflow-x: hidden;
    height: 100%;
    width: 100%;
    z-index: -99999;
    background-attachment: fixed;
}


.contain{
    margin-top: 100px;
    padding-bottom: 45px;
    text-align: center;
    font-size: x-large;
    color: white;
    font-family: 'Boogaloo', cursive;
    
   

}

.item{
    transition: 1s;
    animation-name: fadeOutLeftBig;
    animation-duration: 2s;
    &:hover {
        
        -webkit-transform: scale(1.15);
        -ms-transform: scale(1.15);
        transform: scale(1.15);
      }
}

h1{
    color: white;
}

.item{
    display: inline-block;
}

